import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/font/google/target.css?{\"path\":\"../../packages/shadcn-kit/lib/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/Combobox.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/Dialogs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/Dropzone.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/Inspector.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/alert-dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/aspect-ratio.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/calendar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/checkbox.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/command.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/dropdown-menu.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/form.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/hover-card.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/input.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/label.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/popover.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/progress.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/scroll-area.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/select.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/slider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/switch.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/tabs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/toast/toaster.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/toast/use-toast.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/toggle.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/toolbar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/packages/shadcn-kit/styles/globals.css")